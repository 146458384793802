<template>
    <div>
        <b-alert variant="success" :show="success">Successfully uploaded candidate endorsements.</b-alert>
        <b-alert variant="danger" :show="errorMessage != null">{{ errorMessage }}</b-alert>
        <b-form @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col>
                    <b-form-group label="Select Candidate:" label-class="font-weight-bold pt-0">
                        <b-form-select v-model="selectedCandidate" :options="candidateOptions"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Select File:" label-class="font-weight-bold pt-0">
                        <b-form-file accept=".xlsx" v-model="file" placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."></b-form-file>
                    </b-form-group>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-button v-if="uploading" type="button" variant="primary" disabled>
                <b-spinner small></b-spinner>
                <span> Uploading...</span></b-button>
            <b-button v-else type="submit" variant="primary">
                Upload</b-button>
            <b-button type="reset" variant="secondary" class="ml-2">Reset</b-button>
            <b-button type="button" variant="secondary" class="ml-2" @click="onDownloadTemplate">Download
                Template</b-button>

        </b-form>
    </div>
</template>

<script>
import Repository from "@/repositories/RepositoryFactory";
const SettingsRepository = Repository.get("SettingsRepository");
export default {
    name: "UploadEndorsements",
    data() {
        return {
            file: null,
            loading: false,
            errorMessage: null,
            success: false,
            uploading: false,
            candidates: [],
            selectedCandidate: "",
            candidateOptions: []
        };
    },
    computed: {},
    async mounted() {
        const candidates = await this.$store.dispatch(
            "candidates/getAllCandidates",
            null
        )

        this.candidateOptions = candidates.filter(x => x.name).map(candidate => {
            return {
                value: candidate.userId,
                text: candidate.name
            }
        })
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();
            if (this.selectedCandidate.length == 0) {
                this.errorMessage = "Please select candidate.";
                return;
            }
            if (this.file == null) {
                this.errorMessage = "Please select file.";
                return;
            }
            console.log(this.selectedCandidate)
            this.errorMessage = null;
            this.uploading = true;
            setTimeout(async () => {
                try {
                    await SettingsRepository.UploadCandidateEndorsements(
                        {
                            file: this.file,
                            userId: this.selectedCandidate
                        }
                    );
                    this.success = true;
                } catch (error) {
                    console.error(error);
                    this.errorMessage = "Server error occured. Please make sure that you are using the correct template format.";
                }
                this.uploading = false;
            }, 500);
        },
        onReset() {
            this.file = null;
            this.selectedCandidate = "";
            this.errorMessage = null;
            this.success = false;
        },
        onDownloadTemplate() {
            if (this.selectedCandidate.length == 0) {
                this.errorMessage = "Please select candidate.";
                return;
            }
            this.errorMessage = null;
            SettingsRepository.DownloadEndorsementsTemplate({
                userId: this.selectedCandidate,
                name: this.candidateOptions.find(x => x.value == this.selectedCandidate).text
            });
        }
    },
};
</script>
